import { useState } from 'react';
import { cn } from '~/utils/cn';
import { useInputContainerProps, InputContainer } from './InputContainer';
import type { InputProps, InputSize } from './types';

import { Prefix, Suffix } from './Xfix';
import { EyeIcon, EyeOffIcon } from 'lucide-react';

export const BaseInput = ({
  ref,
  size = 'md',
  prefix,
  suffix,
  ...props
}: Omit<React.ComponentPropsWithRef<'input'>, 'size' | 'prefix'> & {
  size?: InputSize;
  suffix?: React.ElementType;
  prefix?: React.ElementType;
}) => {
  const [show, setShow] = useState(false);
  const isPassword = props.type === 'password';

  return (
    <span className="relative">
      <Prefix content={prefix} size={size} />
      <input
        ref={ref}
        autoComplete="off"
        {...props}
        type={show ? 'text' : props.type}
        className={cn(
          'ring-offset-background focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
          'inline-block w-full appearance-none border bg-background text-foreground outline-hidden transition-all',
          'placeholder:text-sm placeholder:italic focus-within:ring-border disabled:text-muted-foreground',
          'disabled:cursor-not-allowed disabled:bg-muted',
          'read-only:cursor-text read-only:bg-muted/30 read-only:focus-within:ring-0',

          {
            'min-h-10 rounded-md pl-4': size === 'md',
            'h-7 rounded-sm pl-2 text-sm': size === 'sm',
            'pl-11!': prefix != null && size === 'md',
            'pl-6!': prefix != null && size === 'sm',
            'pr-10': suffix != null,
          },
          props.className,
        )}
      />
      <Suffix
        size={size}
        content={isPassword ? (show ? EyeOffIcon : EyeIcon) : suffix}
        className={cn({ 'cursor-pointer': isPassword })}
        onClick={isPassword ? () => setShow((p) => !p) : undefined}
      />
    </span>
  );
};

BaseInput.displayName = 'BaseInput';

export const Input = ({ ref, ...props }: InputProps<'input', true>) => {
  const { inputContainerProps, ..._props } = useInputContainerProps(props);

  return (
    <InputContainer {...inputContainerProps}>
      <BaseInput
        ref={ref}
        {..._props}
        className={cn(
          {
            'invalid:border-red-500 invalid:outline-red-500': !!inputContainerProps.error,
            'border-border': !inputContainerProps.error,
          },
          _props.className,
        )}
      />
    </InputContainer>
  );
};

Input.displayName = 'Input';
